.button {
  cursor: pointer;
  width: 2.8em;
  height: 2.8em;
  padding: 0 1ex;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #fff;
  /* border: 1px solid #000; */
  border: 1px transparent;
  box-shadow: 0 0 1ex rgb(180, 180, 180);
  z-index: 1;
}
.button:active {
  box-shadow: none;
  border: 1px black solid !important;
}

.button[disabled] {
  cursor: not-allowed;
  opacity: 0.4;
}

.button--deprioritised {
  opacity: 0.4;
}

.button--unstyled {
  width: auto;
  height: auto;
  border: 0;
}

.button--secondary {
  width: auto;
  border-radius: 16px;
  padding: 8px 16px;
}

.button--secondary > * {
  margin-right: 8px;
}

.button--secondary > *:last-child {
  margin-right: 0;
}
