.textPost {
  max-width: 500pt;
  min-width: 80pt;
  height: fit-content;
  padding: 0.6em;
  border: 0.01em solid gainsboro;
  border-radius: 2pt;
  /* position: relative; */
  box-shadow: 0 0 1ex gainsboro;
  margin: 10pt;
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.textPost.playing {
  outline: 4px solid;
  animation: oscillate 8s infinite;
}
.textPost.breadcrumb {
  opacity: 0.6;
  box-shadow: none;
  margin: 0.5ex 0;
}

.textPost:not(.expanded) {
  cursor: pointer;
}
.textPost:not(.expanded):active {
  outline: solid 1px gray;
}
.textPost.expanded {
  box-shadow: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px gainsboro solid;
  border-radius: 0;
  margin: 0;
  max-height: fit-content;
  box-shadow: none;
  z-index: 1;
}

.textPost:not(.breadcrumb) + .textPost:not(.breadcrumb),
.feed-item-with-connect + .feed-item-with-connect {
  margin-top: 1em;
}
.textPost.personPreview {
  min-height: 42pt;
}

.audio-thought-container {
  cursor: pointer;
}

.thought-info-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  min-width: 0;
}

.textpost-title {
  margin: 1ex 0;
  font-size: 1.1em;
  cursor: default;
}

.textpost-title.mine {
  cursor: pointer;
}

.thought-info {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  color: rgb(200, 200, 200);
}

.updates {
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
}

.updates-indicator {
  background-color: red;
  width: 8px;
  height: 8px;
  margin-right: 4px;
  border-radius: 100%;
}

.thought-byline {
  display: flex;
}

.thought-author {
  display: flex;
  cursor: pointer;
}

.thought-author:hover {
  text-decoration: underline;
}

.thought-reply {
  margin-left: 4px;
}

.thought-reply-arrow {
  margin-right: 4px;
}

.button-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.more-actions-button {
  color: inherit;
  font-size: inherit;
  gap: 4px;
  box-shadow: none;
}

.more-actions--expanded .more-actions-button {
  color: black;
}

.more-actions-button:hover .more-actions-text {
  text-decoration: underline;
}

.more-actions-arrow-up,
.more-actions-arrow-down {
  height: 16px;
  align-items: center;
}

.more-actions-arrow-up {
  display: none;
}

.more-actions--expanded .more-actions-arrow-up {
  display: flex;
  font-size: 20px;
}

.more-actions-arrow-down {
  display: flex;
}

.more-actions--expanded .more-actions-arrow-down {
  display: none;
}

.more-action-menu {
  display: none;
}

.more-actions--expanded .more-action-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid gainsboro;
  z-index: 1;
  background: white;
}

.action-button {
  display: flex;
  align-items: center;
  border-bottom: 1px solid gainsboro;
  border-radius: 0;
  width: 100%;
  padding: 8px;
}

.action-button:last-child {
  border-bottom: 0;
}

.action-button:hover {
  color: black;
}

.action-button-text {
  margin-left: 4px;
}

.expanded-related-thoughts-header {
  color: rgb(200, 200, 200);
  margin: 1ex;
  font-style: italic;
  font-size: 1.2em;
}

.mini-truncated-display {
  color: #a1a1a1;
  /* font-size: 0.9em; */
  /* font-style: italic; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 2px;
}
