.orientation-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  transition: opacity 0.5s ease-out;
}
.close-section {
  margin: 15px;
  max-width: 80%;
  min-width: 50%;
  width: 80%;
  display: flex;
  flex-direction: row-reverse;
}

.slide-container {
  padding: 15px;
  margin: 0 auto;
  max-width: 80%;
  min-width: 50%;
  height: 5vh;
  /* max-height: 10vh;
  min-height: 10vh; */
  align-items: center;
  align-self: center;
  /* border: solid 1px black; */
  text-align: center;
  display: flex;
  flex-direction: column;
}
.slide-text {
  max-width: 60vw;
  line-height: 1.5;
}

.nav-buttons {
  display: flex;
  align-self: center;
  cursor: pointer;
  font-size: 1em;
}
.orientation-container.loading {
  opacity: 0;
}
.example-thought {
  max-width: 24em;
  margin: 0.8em 2em;
  padding: 0.8em;
  border-radius: 0.5em;
  background-color: rgb(239, 240, 246);
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.75em;
}
.next-button {
  /* font-size: 1.3em; */
  padding: 0em 0.4em;
  /* color: rgb(255, 154, 154); */
  color: rgb(0, 203, 186);
  /* background-color: rgb(114, 250, 255); */
  border-radius: 0.3em;
  width: 120px;
  text-align: center;
  /* position: relative; */
  user-select: none;
  /* transition: color .6s; */
}
