.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f3f4f6;
  flex-direction: column;
}

.signing-in {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.2ex;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.login-input {
  font-size: 1.15em;
  padding: 1.2ex;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 100%;
  outline: none;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.login-button {
  font-size: 1.15em;
  padding: 1.2ex;
  /* margin-bottom: 10px; */
  border-radius: 5px;
  border: none;
  cursor: pointer;
  color: white;
  width: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.login-button.google {
  background-color: #34a853;
}

.login-button.email {
  background-color: #4285f4;
}
