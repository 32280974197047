.post-list-container {
  /* margin-top: 8px; */
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.post-list {
  flex: 1;
  overflow-y: auto;
  max-width: 450pt;
  max-width: 100vw;
  align-self: center;
  /* display: flex; */
  /* flex-direction: column; */
}

.post-list-footer {
  flex: 0;
  display: flex;
  width: 100vw;
  flex-direction: column;
  border-radius: 0;
  z-index: 1;
  padding: 1ex;
  /* background-color: rgb(235, 235, 235); */
  background-color: rgb(244, 244, 244);
  box-shadow: 0 0 12px 1px rgb(199, 199, 199);
  position: fixed;
  left: 0;
  bottom: 0;
}
