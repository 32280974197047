/* AudioRecorder.css */

.recording-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 12%;
}

.question-mark {
  font-size: 1.6em;
  color: gainsboro;
  cursor: pointer;
  transition: font-size 1s, rotate 1s, color 1s ease-out;
  font-family: Inter;
}
.question-mark:active {
  color: red;
}
.question-mark.expanded{
  font-size: 1em;
  rotate: 180deg;
}



