.expandable-feed-item-container  {

    display: flex;
    flex-direction: column;
    align-items: stretch;
    cursor: pointer;
    /* margin-bottom: 2ex; */
    
}
.expandable-feed-item-container.expanded 
{
    border-radius: 2pt;
    position: relative;
    z-index: 11;

    /* used to be border, but box shadow more consistent */
        /* border: 1px gray solid; */
    box-shadow: 0 0 1ex gainsboro;

    /* min-width: 500pt; */
}
.expandable-feed-item-container.expanded.fullPage {
    max-height: 92vh;
    /* height: fit-content; */
    /* flex: 1; */
    /* height */
}

.feed-item-with-connect {
    display: flex;
    justify-content: stretch;
    align-items: center;
}
.expandable-background-blocker{
    position: absolute;
    top: 0; left: 0;
    width: 100vw; height: 99999pt;
    background-color: rgba(255, 255, 255, .9); 
    z-index: 5;
}

.associated-thoughts-section-in-post {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.podcast-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    font-size: 1.4em;
    padding: 1.6em;
    background-color: gainsboro;
    box-shadow: none;
    font-family: monospace;
    margin-bottom: 0;
    font-weight: bold;

}

.podcast-button:hover {
    /* background-color: #e6e6e6;
    border-color: #adadad; */
}
.podcast-button.disabled {
    opacity: .5;
    cursor: pointer;
}

.podcast-button span {
    margin-right: 8px;
}
