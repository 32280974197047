.orientation-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  transition: opacity 0.5s ease-out;
}
.nav-buttons {
  display: flex;
  cursor: pointer;
  font-size: 1em;
}
.orientation-container.loading {
  opacity: 0;
}
.example-thought {
  max-width: 24em;
  margin: 0.8em 2em;
  padding: 0.8em;
  border-radius: 0.5em;
  background-color: rgb(239, 240, 246);
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.75em;
}
.next-button {
  /* font-size: 1.3em; */
  padding: 0em 0.4em;
  /* color: rgb(255, 154, 154); */
  color: rgb(0, 203, 186);
  /* background-color: rgb(114, 250, 255); */
  border-radius: 0.3em;
  text-align: center;
  user-select: none;
  /* transition: color .6s; */
}

.slide-text {
  margin: 0 1em;
  text-align: center;
  max-width: 100vw;
  overflow-wrap: break-word;
}
