:root {
  --bubble: 0 0 0.3em rgb(184, 184, 184);
  --airy-smoke: rgb(250, 250, 250);
}
.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.row {
  display: flex;

  flex-direction: row;
  justify-content: center;
}
.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.panel {
  flex: 1;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 500pt;
  overflow-y: hidden;

  /* margin: 0 auto; */
}
.panel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}
.secondary-panel {
  padding: 0;
}

@keyframes extra-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pulse-slow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(20deg);
  }
}
.pulse,
.extra-pulse {
  animation: pulse 1.5s infinite;
}
.pulse-slow {
  animation: pulse 2.5s infinite;
}
.spinner {
  animation: spin 2s linear infinite;
}
.slow-spinner {
  animation: spin 200s linear infinite;
}
.slow-spinner.extra-pulse {
  animation: spin 4s linear infinite, pulse 1.5s infinite;
}

@keyframes oscillate {
  0%,
  100% {
    outline-color: rgba(255, 0, 0, 0.486);
  }
  20% {
    outline-color: rgba(255, 0, 128, 0.6);
  }
  40% {
    outline-color: rgba(128, 0, 255, 0.3);
  }
  60% {
    outline-color: rgba(0, 128, 255, 0.6);
  }
  80% {
    outline-color: rgba(0, 255, 128, 0.3);
  }
}

.app-view-toggle {
  margin: 1ex 0 0;
}
.app-view-toggle > * {
  cursor: pointer;
  color: rgb(181, 181, 181);
  font-size: 1.4em;
  border-radius: 5pt;
  padding: 0.4em;
  padding-bottom: 0.2em;
}
.toggle-option.selected {
  color: gray;
  background-color: rgb(240, 240, 240);
}

.bold-view {
  font-weight: bold;
  color: black;
}
