.public-thought-page-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.clickable-icon {
    cursor: pointer;
    margin-right: 5px;
    color: rgb(170, 170, 170);
}

.clickable-icon:active {
    color: gainsboro;
}

