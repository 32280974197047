.person-container {
  cursor: pointer;
  height: fit-content;
  padding: 2ex;
  margin: 2ex;
  border: 0.01em solid gainsboro;
  border-radius: 2pt;
  box-shadow: 0 0 1ex gainsboro;
  margin: 5pt 10pt;
  background-color: white;
  display: flex;
 
  justify-content: space-between;
  align-items: center;
}

.person-container--friends {
  /* border-color: green; */
}

.person-name {
  /* margin: 0; */
  font-size: 1.1em;
  height: 32px;
  display: flex;
  min-width: 5em;
  align-items: center;
}

.person-right-container {
  display: flex;
  align-items: center;
}

.person-thought-count {
  margin-right: 8px;
  color: rgb(200, 200, 200);
  /* overflow-wrap: break-word; */
  letter-spacing: 0;
  font-size: 0.9em;
}

.person-thought-count:last-child {
  margin-right: 0;
}

.person-unlocked-thoughts {
  margin: 0 8px;
}

.person-total-thoughts {
  margin: 0 8px;
}

.person-requested-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}
