.people-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: auto;
  margin-bottom: 72px;
  max-width: 450pt;
  align-self: center;

  /* gap: 2ex; */
}

.people-sorter {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: .8em;
  margin: 16px 0;
  color: gray;
}
