.landing-page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.1em;
  text-align: left;
  font-family: courier;
  position: relative;
}
.landing-page::before {
  content: "";
  /* background-image: url("../../assets/social-notebook.png"); */
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100vh;
  z-index: -1;
  transition: opacity 0.3s;
}

.landing-page:active.landing-page::before {
  opacity: 1;
  z-index: 2;
}

.landing-page-text {
  max-width: 32em;
  margin: 0 2ex;
  /* padding: 2em; */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
}

.top-part {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.big-statement {
  padding: 1em;
  padding-bottom: 0.5em;
  font-size: xx-large;
}

.enter-plexus {
  background-color: rgb(244, 244, 244);
  width: fit-content;
  border-radius: var(--thought-border-radius);
  padding: 0.5em;
  margin: 1em;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.enter-plexus:hover {
  text-decoration: underline;
  background-color: rgb(236, 239, 246);
}

.enter-plexus:active {
  color: white;
  font-family: cursive;
  font-size: 24pt;
  font-weight: bold;
  background: rgb(51, 0, 255);
  box-shadow: 0 0 0 1000pt rgb(51, 0, 255);
  z-index: 10;
  text-decoration: none;
}

.link-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  opacity: 0.4;
  flex-wrap: wrap;
}
.link-row:active {
  z-index: 3;
}

.link-row > * {
  padding: 1ex;
  font-style: italic;
  color: rgb(50, 50, 50);
}

.primary-enter-button {
  padding: 0.5em;
  margin: 0.2em;
  font-size: 1em;
  box-shadow: 0 0 20px 2px gray;
  cursor: pointer;
  border-width: 2pt;
  max-width: 80vw;
  width: 170pt;
  vertical-align: center;
  font-weight: bold;
  background-color: rgba(212, 212, 212, 0.486);
  display: flex;
  align-items: center;
  justify-content: center;

  transition: padding 0.5s, width 1s, background-color 1s, color 0.5s, border-radius 0.4s,
    font-size 0.5s, border-color 2s;
}
.primary-enter-button:active {
  z-index: 3;
  /* width: 540pt; */
  /* font-size: 1.8em; */
  background-color: transparent;
  border: none;
  border-color: transparent;
  box-shadow: none;
  color: white;
  /* font-weight: bold; */
}

.secondary-enter-button:active {
  z-index: 3;
}
.secondary-enter-button {
  padding: 0.5em;
  margin: 0.2em;
  font-size: 1em;
  max-width: 80vw;
  width: 170pt;
  background-color: none;
  opacity: 0.3;
  border-width: 2pt;
  background-color: transparent;
  cursor: pointer;
}

.voices-button {
  font-size: 2ex;
  font-family: courier;
  cursor: pointer;
  padding: 1ex 2ex;
  display: flex;
  align-items: center;
  z-index: 3;
  font-style: italic;
  border-width: 2px;
  /* border: transparent; */
  /* background-color: transparent; */
}
.voices-button:hover {
  font-weight: bold;
}
