.person-detail-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.person-detail-name-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 2em;
  align-items: baseline;
}

.person-detail-name {
  margin: 0;
}
